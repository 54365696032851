import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Global } from '@emotion/core';
import { ThemeProvider, merge, Flex, Box, css } from 'theme-ui';
import baseTheme from '@solid-ui-theme';
import pageContextProvider from '@helpers/pageContextProvider';
import { FormContextProvider } from '@solid-ui-components/ContentForm';
import { ModalContextProvider } from '@solid-ui-components/Modal';
import { TabsContextProvider } from '@solid-ui-components/Tabs';
import ColorMode from '@solid-ui-components/ColorMode';
import { Link } from 'gatsby';

const Layout = ({ children, pageContext = {}, location, theme = {} }) => {
  return (
    <ThemeProvider theme={merge(baseTheme, theme)}>
      <CookieConsent
        overlay
        location={'bottom'}
        visible={'byCookieValue'}
        buttonText={'J\'accepte tous les cookies'}
        declineButtonText={'Refuser'}
        cookieName={'moenn-technologies-gdpr-google-analytics'}>
        Ce site internet est de MOENN Technologies, il utilise des Cookies pour des fins d'amélioration de l'expérience utilisateur. {' '}
      </CookieConsent>
      <pageContextProvider.Provider value={{ pageContext, location }}>
        <FormContextProvider>
          <ModalContextProvider>
            <TabsContextProvider>
              <Flex variant='layout.layout'>
                <Global styles={css(theme => theme.global)} />
                <ColorMode />
                <Box variant='layout.body'>{children}</Box>
              </Flex>
            </TabsContextProvider>
          </ModalContextProvider>
        </FormContextProvider>
      </pageContextProvider.Provider>
    </ThemeProvider>
  )
}

export default Layout
